<template>
  <tbody @click="$emit('click')" :class="{selected: selected}">
    <tr class="single">
      <td v-for="column in schemas" :key="column.label" :style="{'min-width': column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto',textAlign: column.align}">
        <div v-if="column.item.inputType === 'checkbox'" class="goods_line_checkbox submit" style="text-align: center">
          <el-checkbox
            v-model="row[column.group][column.key]"
            :true-label="1"
            :false-label="0"
            :disabled="true"
          />
        </div>
        <tw-overflow-tooltip v-else-if="column.dataType === 'SystemDate'" :content="row[column.group][column.key] | dateTimeFormat" />
        <tw-overflow-tooltip v-else-if="column.item.inputType === 'date'" :content="row[column.group][column.key] | utcOffsetDateFormat" />
        <tw-overflow-tooltip v-else-if="column.item.code === 'IP_REQUEST_TYPE'" :content="getSegmentValue(row[column.group][column.key], IP_REQUEST_TYPE_VARIABLES)" />
        <tw-overflow-tooltip v-else-if="column.item.code === 'IP_REQUEST_STATUS'" :content="getSegmentValue(row[column.group][column.key], IP_REQUEST_STATUS_VARIABLES)" />
        <tw-overflow-tooltip v-else :content="row[column.group][column.key]" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
import { IP_REQUEST_TYPE_VARIABLES, IP_REQUEST_STATUS_VARIABLES } from 'lib-tw-common';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';

export default {
  name: 'TwProcessGroupListPreviewIpRequestTable',
  props: {
    item: Object,
    selected: Boolean,
    row: Object,
    schemas: Array
  },
  components: {
    TwOverflowTooltip
  },
  data() {
    return {
      IP_REQUEST_TYPE_VARIABLES,
      IP_REQUEST_STATUS_VARIABLES
    };
  },
  computed: {},
  methods: {
    getSegmentValue(value, variables) {
      return _.get(_.find(variables, { code: value }), 'label', '');
    }
  }
};
</script>

<style lang="scss" scoped>
tbody:hover {
  background-color: $color_gray_background;

  td.goods_line_table_inner img.close_times {
    opacity: 1;
  }
}

tbody.selected {
  background-color: $color_selected_row;
}

tbody.invalid td:not(.goods_line_table_inner) {
  background-color: rgba(224, 0, 1, 0.07);
}

tr {
  height: 40px;
}

th {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  color: #5e5873;
  padding: 4px 5px;
  border: 1px solid $color_gray_300;
  background: $color_dark_blue;
  color: white;
  // font-weight: bold;
}

td {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0;
  border-left: 1px solid $color_gray_300;
  height: 30px;
  vertical-align: middle;
  padding: 0 5px;
  font-size: 12px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

tr.single td {
  border-bottom: 1px solid $color_gray_400 !important;
}

td.goods_line_table_inner {
  position: relative;
  padding: 0;
  border-bottom: 1px solid $color_gray_400 !important;
  // border-top: 1px solid #666;

  img.close_times {
    position: absolute;
    right: 4px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0;
  }

  table {
    table-layout: fixed;
    min-width: 100%;
    border: 0;
    border-collapse: collapse;

    th {
      border-top: 0 !important;
      border-bottom: 0;
      padding: 0 5px;
      line-height: 0;
      height: 0;
      overflow: hidden;
      background: transparent;
    }

    td {
      border-top: 0 !important;
      border-bottom: 0 !important;
      padding: 0 5px;
    }

    tr th:first-child,
    tr td:first-child {
      border-left: 0 !important;
    }

    tr th:last-child,
    tr td:last-child {
      border-right: 0 !important;
    }
  }
}
</style>
